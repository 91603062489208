import { Box, Circle, Link, Stack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'

type Testimonial = {
  quote: JSX.Element
  authorDetail: JSX.Element
  authorImageUrl: string
}

export type HomepageSection = {
  eyebrow: JSX.Element
  heading: JSX.Element
  description: JSX.Element | JSX.Element[]
  testimonial: Testimonial
  videoUrls: {
    mp4: string
    webm: string
  }
}

const EmojiListItem = ({
  emoji,
  emojiDescription,
  text,
}: {
  emoji: string
  emojiDescription: string
  text: JSX.Element
}) => {
  return (
    <Box width="fit-content">
      <Stack direction="row" align="flex-start" spacing={2}>
        <Circle
          border="1px solid #fcd6e9"
          bg="white"
          p={2}
          size={{ base: 8, md: 10 }}
          boxShadow="md"
        >
          <span role="img" aria-label={emojiDescription}>
            {emoji}
          </span>
        </Circle>
        <Text lineHeight={{ base: 1.65, lg: 2 }}>{text}</Text>
      </Stack>
    </Box>
  )
}
export const ORDERED_HOMEPAGE_SECTIONS: HomepageSection[] = [
  {
    eyebrow: <Trans>Never start from scratch</Trans>,
    heading: <Trans>Generate docs, decks & webpages in seconds</Trans>,
    description: (
      <Text>
        <Trans>
          Create a <strong>working presentation</strong>,{' '}
          <strong>document</strong> or <strong>webpage</strong> you can refine
          and customize in under a minute, using our powerful{' '}
          <strong>AI generator</strong>.
        </Trans>
      </Text>
    ),
    testimonial: {
      quote: (
        <Trans>
          Gamma allows me to package up information in ways I can't with slides,
          while still creating good flow for my presentations.
        </Trans>
      ),
      authorDetail: <Trans>Ann Marie, Director of Product at Koalafi</Trans>,
      authorImageUrl:
        'https://d2x8ygf1qnoedj.cloudfront.net/images/annmarie.jpg',
    },
    videoUrls: {
      mp4: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/AI-editing-compressed-transcode.mp4',
      webm: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/AI-editing-compressed-transcode.webm',
    },
  },
  {
    eyebrow: <Trans>Beautiful and on-brand</Trans>,
    heading: <Trans>Make it polished and ready in one click</Trans>,
    description: (
      <Stack>
        <EmojiListItem
          emoji="🎉"
          emojiDescription={t`Party emoji`}
          text={
            <Trans>
              <strong>No more</strong> slide masters or template lock-in
            </Trans>
          }
        />
        <EmojiListItem
          emoji="🎨
          "
          emojiDescription={t`Paint palette emoji`}
          text={
            <Trans>
              Restyle your entire deck in just <strong>one click</strong>
            </Trans>
          }
        />
        <EmojiListItem
          emoji="⏰"
          emojiDescription={t`Clock emoji`}
          text={
            <Trans>
              Use a <strong>flexible template</strong> to work faster
            </Trans>
          }
        />
      </Stack>
    ),
    testimonial: {
      quote: (
        <Trans>
          Creating polished slides gets really tedious, especially aligning
          boxes and keeping things on-brand. With Gamma, I can get things done
          <strong>so much faster</strong>.
        </Trans>
      ),
      authorDetail: <Trans>John, Co-founder & CEO at Stotles</Trans>,
      authorImageUrl:
        'https://d2x8ygf1qnoedj.cloudfront.net/images/John-Witt.jpg',
    },
    videoUrls: {
      mp4: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/animation2_62f5b7dcbbc3b2103e4b955c_2-transcode.mp4',
      webm: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/animation2_62f5b7dcbbc3b2103e4b955c_2-transcode.webm',
    },
  },
  {
    eyebrow: <Trans>Context with brevity</Trans>,
    heading: <Trans>Present live, or send a webpage</Trans>,
    description: (
      <Stack>
        <EmojiListItem
          emoji="💡"
          emojiDescription={t`Lightbulb emoji`}
          text={
            <Trans>
              Get your point across <strong>with present mode</strong>
            </Trans>
          }
        />
        <EmojiListItem
          emoji="🔎"
          emojiDescription={t`Magnifying glass emoji`}
          text={
            <Trans>
              Drill into the details using <strong>nested cards</strong>
            </Trans>
          }
        />
        <EmojiListItem
          emoji="💬"
          emojiDescription={t`Speech bubble emoji`}
          text={
            <Trans>
              Share online with <strong>publishing + analytics</strong>
            </Trans>
          }
        />
      </Stack>
    ),
    testimonial: {
      quote: (
        <Trans>
          Notion x Canva is the best way to think about Gamma. It's just{' '}
          <strong>so nice to use</strong>!
        </Trans>
      ),
      authorDetail: (
        <Trans>Matthias, Senior Strategy Consultant at ODALINE</Trans>
      ),
      authorImageUrl:
        'https://d2x8ygf1qnoedj.cloudfront.net/images/Matthias.jpg',
    },
    videoUrls: {
      mp4: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/animation3_62ec0a91270e4119870a63c9-transcode.mp4',
      webm: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/animation3_62ec0a91270e4119870a63c9-transcode.webm',
    },
  },
  {
    eyebrow: <Trans>Concise, visual, and interactive</Trans>,
    heading: <Trans>Bring your ideas to life</Trans>,
    description: (
      <Stack>
        <Text>
          <Trans>
            Move beyond walls of text and boring clipart. Embed anything:
          </Trans>
        </Text>
        <Stack direction={{ base: 'column', md: 'row' }}>
          <EmojiListItem
            emoji="🎆"
            emojiDescription={t`Fireworks emoji`}
            text={<Trans>GIFs</Trans>}
          />
          <EmojiListItem
            emoji="🎥"
            emojiDescription={t`Video camera emoji`}
            text={<Trans>Videos</Trans>}
          />
          <EmojiListItem
            emoji="📈"
            emojiDescription={t`Chart emoji`}
            text={<Trans>Charts</Trans>}
          />
          <EmojiListItem
            emoji="💻"
            emojiDescription={t`Laptop emoji`}
            text={<Trans>Websites</Trans>}
          />
        </Stack>
      </Stack>
    ),
    testimonial: {
      quote: (
        <Trans>
          We've avoided PowerPoint decks at all costs at our agency, but Gamma
          offered us a <strong>fresh alternative</strong> that we love to share
          with potential clients. Polished and adaptable. We love it!
        </Trans>
      ),
      authorDetail: <Trans>Emilie, Founder & Principal at Six Eastern</Trans>,
      authorImageUrl: 'https://d2x8ygf1qnoedj.cloudfront.net/images/Emilie.jpg',
    },
    videoUrls: {
      mp4: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/animation4_62ec0d24c8eb833a3285c37d-transcode.mp4',
      webm: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/animation4_62ec0d24c8eb833a3285c37d-transcode.webm',
    },
  },
  {
    eyebrow: <Trans>Engage users on any device</Trans>,
    heading: <Trans>Read anywhere</Trans>,
    description: (
      <Stack>
        <EmojiListItem
          emoji="📊"
          emojiDescription={t`Graph emoji`}
          text={
            <Trans>
              Measure engagement with <strong>built-in analytics</strong>
            </Trans>
          }
        />
        <EmojiListItem
          emoji="😁"
          emojiDescription={t`Grinning= emoji`}
          text={
            <Trans>
              Get more feedback with <strong>quick reactions</strong>
            </Trans>
          }
        />
        <EmojiListItem
          emoji="💬"
          emojiDescription={t`Speech bubble emoji`}
          text={
            <Trans>
              <strong>Comments</strong> make collaboration straightforward
            </Trans>
          }
        />
        <EmojiListItem
          emoji="📱"
          emojiDescription={t`Mobile phone emoji`}
          text={
            <Trans>
              Share on <strong>any device</strong> — laptop, tablet, or phone
            </Trans>
          }
        />
      </Stack>
    ),
    testimonial: {
      quote: (
        <Trans>
          I'm not a slide deck artist - Gamma makes it super easy for me to
          convert my documents into engaging presentations for company all
          hands, and takes me <strong>10x less time</strong> than fiddling about
          with pixels on slides. Such a time saver.
        </Trans>
      ),
      authorDetail: (
        <Trans>
          Amelia, Co-founder & CEO at{' '}
          <Link
            href="https://joinhoneycomb.com/"
            target="_blank"
            rel="noreferrer"
          >
            Honeycomb
          </Link>
        </Trans>
      ),
      authorImageUrl: 'https://d2x8ygf1qnoedj.cloudfront.net/images/amelia.jpg',
    },
    videoUrls: {
      mp4: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/animation5_62ec17917adc45828e63632e-transcode.mp4',
      webm: 'https://d2x8ygf1qnoedj.cloudfront.net/videos/animation5_62ec17917adc45828e63632e-transcode.webm',
    },
  },
]

export const HOME_CONTAINER_MAX_WIDTH = {
  lg: '1080px',
  xl: '1200px',
}
