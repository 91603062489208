import { Messages, i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { ReactNode } from 'react'

import { SupportedLocaleKey } from './constants'
import { useLinguiInitSSG } from './hooks/useLinguiInitSSG'

export const I18nProviderWrapperMarketing = ({
  children,
  locale,
  messages,
}: {
  children: ReactNode
  locale: SupportedLocaleKey
  messages: Messages
}) => {
  useLinguiInitSSG({ locale, messages })
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
