import { i18n } from '@lingui/core'
import { GetStaticPaths, GetStaticProps } from 'next'
import { useEffect } from 'react'

import { SUPPORTED_LOCALE_OPTIONS, SupportedLocaleKey } from '../constants'
import { getSupportedLocaleKeyOrUseFallback } from '../utils/localeKey'

export async function loadCatalog(locale: string) {
  const catalog = await import(
    `@lingui/loader!/src/translations/${locale}/messages.po`
  )
  return catalog.messages
}

export const getStaticPropsMarketing: GetStaticProps = async ({ params }) => {
  const locale = params?.locale
  const localeToUse = getSupportedLocaleKeyOrUseFallback(locale as string)
  const messages = await loadCatalog(localeToUse)

  return {
    props: {
      locale: localeToUse,
      messages,
    },
    // Revalidate every 24 hours at most
    revalidate: 60 * 60 * 24,
  }
}

export const getStaticPathsMarketing: GetStaticPaths = async () => {
  const localeKeys = Object.keys(SUPPORTED_LOCALE_OPTIONS)

  const paths = localeKeys.map((locale) => {
    return {
      params: {
        locale,
        // Do we need this?
      },
    }
  })
  return { paths, fallback: 'blocking' }
}

export function useLinguiInitSSG({
  locale,
  messages,
}: {
  locale: SupportedLocaleKey
  messages: any
}) {
  const isClient = typeof window !== 'undefined'

  if (!isClient && locale !== i18n.locale) {
    // there is single instance of i18n on the server
    // note: on the server, we could have an instance of i18n per supported locale
    // to avoid calling loadAndActivate for (worst case) each request, but right now that's what we do
    i18n.loadAndActivate({ locale, messages })
  }
  if (isClient && !i18n.locale) {
    // first client render

    i18n.loadAndActivate({ locale, messages })
  }

  useEffect(() => {
    const localeDidChange = locale !== i18n.locale
    if (localeDidChange) {
      i18n.loadAndActivate({ locale, messages })
    }
  }, [locale, messages])

  return i18n
}
